// Copyright 2017-2024 @polkadot/apps authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

let currentClient: ApolloClient<any> | null = null;
let currentEndpointIndex = 0;

const ENDPOINTS = [
  'wss://squid.gdev.coinduf.eu/v1/graphql',
  'wss://duniter-v2-vjrj-squid.comunes.net/v1/graphql'
];

const createNewClient = (url: string) => {
  const wsLink = new GraphQLWsLink(createClient({
    connectionParams: {
      timeout: 5000
    },
    retryAttempts: 3,
    url
  }));

  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      },
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      }
    },
    link: ApolloLink.from([
      onError(({ networkError }) => {
        if (networkError) {
          console.error(`Network Error on endpoint ${currentEndpointIndex}, trying next`);
          switchToNextEndpoint().catch(console.error);
        }
      }),
      wsLink
    ])
  });
};

const switchToNextEndpoint = async () => {
  const nextIndex = currentEndpointIndex + 1;

  currentEndpointIndex = nextIndex >= ENDPOINTS.length ? 0 : nextIndex;

  console.error(`Switching to endpoint ${currentEndpointIndex}: ${ENDPOINTS[currentEndpointIndex]}`);
  const newClient = createNewClient(ENDPOINTS[currentEndpointIndex]);

  if (currentClient) {
    currentClient.stop();
    await currentClient.clearStore();
  }

  currentClient = newClient;
  squidClient = newClient;
};

export let squidClient = createNewClient(ENDPOINTS[0]);
currentClient = squidClient;
